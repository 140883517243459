<template>
  <div class="home">
    <div class="container" v-if="!ismobile">
      <div class="menu">
        <div class="menu-content">
          <div class="right-logo">
            <img src="https://static.lstt.net/kkm/right-logo.svg" alt="">
          </div>
          <div class="center-menu" v-for="(btn, index) in buttons" :key="index">
            <div :class="{ 'underline': clickedButton === index }" @click="setClickedButton(index, btn.id)">
              {{ btn.title }}</div>

          </div>
          <div class="phone">
          </div>
        </div>
      </div>

      <div class="top" ref="section1" id="section1">
        <!-- <div style="height: 5vw;"></div> -->

      </div>
    </div>
    <div style="" class="product-superiority" ref="section2" id="section2">
      <!-- <img src="http://static.lstt.net/lt/product-superiority.svg" alt=""> -->
      <img src="https://static.lstt.net/kkm/special-project.png" alt="">
    </div>
    <div style="background-color: #F7F8F9;" class="product-superiority" ref="section3" id="section3">
      <!-- <img src="http://static.lstt.net/lt/service-part.svg" alt=""> -->
      <img src="https://static.lstt.net/kkm/lawsuit.png" alt="">

    </div>
    <div style="" class="product-superiority" ref="section4" id="section4">
      <!-- <img src="http://static.lstt.net/lt/process.svg" alt=""> -->
      <img src="https://static.lstt.net/kkm/process.png" alt="">

    </div>
    <div class="product-superiority" ref="section5" id="section5" style="background-color: #F3F3F4;">
      <!-- <img src="http://static.lstt.net/lt/process.svg" alt=""> -->
      <img src="https://static.lstt.net/kkm/strict-selection.png" alt="">

    </div>
    <div style="background-color: #F7F8F9;" class="product-superiority" ref="section6" id="section6">
      <!-- <img src="http://static.lstt.net/lt/process.svg" alt=""> -->
      <img src="https://static.lstt.net/kkm/aboutUs.png" alt="">

    </div>


    <div class="bottom-box">
      <div class="bottom-top">
        <div class="right-logo" style="">
          <img src="https://static.lstt.net/kkm/right-logo.svg" alt="">
        </div>
        <div class="company-content">
          <div class="content-box">
            <img src="https://static.lstt.net/kkm/phone.svg" alt="">
            <div class="line"></div>
            <div>010-89986988</div>
          </div>
          <div class="content-box">
            <img src="https://static.lstt.net/kkm/address.svg" alt="">
            <div class="line"></div>
            <div>北京市西城区德胜门外大街11号1幢6层636</div>
          </div>
          <div class="content-box">
            <img src="https://static.lstt.net/kkm/email.svg" alt="">
            <div class="line"></div>
            <div>kf@kekaomao.com</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        @Copyright 2024 北京有界科技有限公司 京ICP备2024073957号-3
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const buttons = [
  { title: '首页', sectionName: 'section1', id: 'section1' },
  { title: '专项业务模块', sectionName: 'section2', id: 'section2' },
  { title: '官司代理服务', sectionName: 'section3', id: 'section3' },
  { title: '服务流程', sectionName: 'section4', id: 'section4' },
  { title: '严选律师', sectionName: 'section5', id: 'section5' },
  { title: '关于我们', sectionName: 'section6', id: 'section6' },
];
const clickedButton = ref(0);

const setClickedButton = (index, sectionId) => {
  console.log(index, sectionId)
  clickedButton.value = index;
  const sectionRef = document.getElementById(sectionId);
  if (sectionRef) {
    sectionRef.scrollIntoView({ behavior: 'smooth' });
  }
};
</script>
<style lang="scss">
.menu {
  height: 5vw;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;

  .menu-content {
    width: 90%;
    // background-color: #fff;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-logo {
      width: 11vw;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;
      }
    }

    .center-menu {
      display: flex;
      align-items: center;
      color: #000;
      font-family: PingFangSC, PingFang SC;
      // font-weight: 600;
      font-size: 0.8vw;

      div {
        width: 100%;
        text-align: center;
        cursor: pointer;
      }

      div:hover {
        // color: #FFE420;
        font-weight: 600;

      }

      .underline {
        text-decoration: underline;
        text-decoration-color: #FFE420;
        font-weight: 600;
        text-decoration-thickness: 0.1vw;
        /* 设置下划线的厚度 */
        text-underline-offset: 0.5vw;
        /* 设置下划线与文字的距离 */
      }

    }

    .phone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 10vw;
      height: 100%;

      .phone-number {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 1.3vw;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 12vw;


        img {
          width: 1.2vw;
          height: 1.2vw;
        }
      }

      .login-button {
        width: 30%;
        height: 2vw;
        background: #FFC875;
        border-radius: 23px;
        line-height: 2vw;
        text-align: center;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 600;
        font-size: 1vw;
        color: #161616;
        cursor: pointer;
      }
    }
  }
}

.top {
  background-image: url('https://static.lstt.net/kkm/kkm-top-bg.svg');
  height: 32vw;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 6vw;

  .content {
    width: 90%;
    margin: auto;
    // background-color: red;
    height: 45vw;

    .content1 {
      position: relative;
    }

    .right-words {
      position: absolute;
      top: 17vw;
      left: 5vw;

      .pen {
        img {
          width: 25vw;
          height: auto;
        }
      }

      .words {
        img {
          width: 15vw;
          height: auto;
          margin-top: 1vw;
        }
      }

      .text3 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 3vw;
        color: #FFFFFF;
        margin: 1vw 0;
      }

      .categories {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 22.5vw;
        align-items: space-between;
        height: 3.5vw;
      }

      .category {
        display: flex;
        align-items: center;
        width: 5vw;
        justify-content: space-between;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 0.9vw;
        color: #FFFFFF;

        img {
          width: 1vw;
          height: auto;
        }
      }
    }

    .form {
      width: 60vw;
      margin: auto;
      height: 40vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .experienceBtn {
        height: 2vw;
        line-height: 2vw;
        text-align: center;
        width: 90%;
        background-color: #1A646A;
        margin: auto;
        border-radius: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 0.8vw;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 0.7vw;
      }
    }
  }
}

.product-superiority {
  width: 100%;
  padding: 0;

  img {
    width: 100%;
  }
}

.bottom-box {
  background-color: #F3F3F4;
  height: 10vw;

  .bottom-top {
    width: 80%;
    margin: auto;
    // background-color: #fff;
    height: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DCDCDC;

    .right-logo {
      width: 7vw;

      img {
        width: 100%;
      }
    }


    .company-content {
      width: 75%;
      display: flex;
      justify-content: space-between;
    }

    .content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 0.8vw;

      .line {
        height: 1.0vw;
        border: 1px solid #999999;
        margin: 0 15px;
      }

      img {
        width: 1.2vw;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 0.6vw;

    color: #999999;
  }
}
</style>